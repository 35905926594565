import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

/**
 * Create an Axios Client with defaults
 */
const instance = axios.create()

/**
 * Request Wrapper with default success/error actions
 */
export const request = async (config: AxiosRequestConfig) => {
	const headers = {
		...config.headers,
		'x-api-key': 'fc0497c8-4798-469c-9cfc-e9d7259cd05b'
	}

	config.headers = headers
	config.baseURL = 'https://rubia.microcms.io/api/v1'

	const onSuccess = (res: AxiosResponse) => {
		console.debug('Request Successful!', res)
		return res.data
	}

	const onError = (err: AxiosError): Promise<AxiosError<any>> => {
		console.error('Request Failed:', err.config)

		if (err.response) {
			// Request was made but server responded with something
			// other than 2xx
			console.error('Status:', err.response.status)
			console.error('Data:', err.response.data)
			console.error('Headers:', err.response.headers)
		} else {
			// Something else happened while setting up the request
			// triggered the error
			console.error('Error Message:', err.message)
		}

		return Promise.reject(err.response || err.message)
	}

	return instance(config).then(onSuccess).catch(onError)
}
